import React from 'react'

export default function PostMeta(props) {
  return (
    <small>
      {props.date} • {'☕️'.repeat(Math.floor(props.min / 5) || 1)} {props.min}{' '}
      min read
    </small>
  )
}
