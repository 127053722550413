import React from 'react'

export default function Footer() {
  return (
    <footer>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eck.im/twitter"
      >
        twitter
      </a>
     {" • "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eck.im/github"
      >
        github
      </a>
      {" • "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eck.im/stackoverflow"
      >
        stackoverflow
      </a>
      {" • "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eck.im/linkedin"
      >
        linkedin
      </a>
    </footer>
  )
}
